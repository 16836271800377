var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "update-resell-ticket-modal",
      "size": "lg",
      "centered": "",
      "no-close-on-backdrop": "",
      "header-bg-variant": "light-info",
      "hide-footer": "",
      "title": _vm.$t('resellTicket.form.title')
    },
    on: {
      "shown": _vm.onShowUpdateModal
    }
  }, [_c('IAmOverlay', {
    attrs: {
      "loading": false
    }
  }, [_c('div', [_c('validation-observer', {
    ref: "refFormObserver"
  }, [_c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('resellTicket.form.type'),
      "vid": "type",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('resellTicket.form.type')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("*")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          class: _vm.getValidationState(validationContext) === false ? 'v-select-is-invalid is-invalid' : '',
          attrs: {
            "id": "type",
            "placeholder": _vm.$t('resellTicket.form.ph.type'),
            "options": _vm.resellTypes,
            "clearable": false,
            "disabled": "",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(data) {
              return [_c('span', [_vm._v(_vm._s(_vm.$t('resellTicket.type.' + data.label)))])];
            }
          }, {
            key: "selected-option",
            fn: function fn(data) {
              return [_c('span', [_vm._v(" " + _vm._s(_vm.$t('resellTicket.type.' + data.label)))])];
            }
          }, {
            key: "no-options",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.resellTicket.type,
            callback: function callback($$v) {
              _vm.$set(_vm.resellTicket, "type", $$v);
            },
            expression: "resellTicket.type"
          }
        }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('resellTicket.form.source')
    }
  }, [_c('v-select', {
    attrs: {
      "placeholder": _vm.$t('resellTicket.form.source'),
      "options": _vm.sources,
      "clearable": false,
      "disabled": "",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.resellTicket.source,
      callback: function callback($$v) {
        _vm.$set(_vm.resellTicket, "source", $$v);
      },
      expression: "resellTicket.source"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('saleReport.columns.airline')
    }
  }, [_c('v-select', {
    attrs: {
      "placeholder": _vm.$t('saleReport.columns.airline'),
      "options": _vm.airlineSupports,
      "reduce": function reduce(airline) {
        return airline.code;
      },
      "label": "code",
      "clearable": false,
      "disabled": "",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.name) + " (" + _vm._s(data.code) + ") ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.name) + " (" + _vm._s(data.code) + ") ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.resellTicket.airlineCode,
      callback: function callback($$v) {
        _vm.$set(_vm.resellTicket, "airlineCode", $$v);
      },
      expression: "resellTicket.airlineCode"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('saleReport.columns.paxType')
    }
  }, [_c('v-select', {
    staticClass: "bg-white",
    attrs: {
      "placeholder": _vm.$t('saleReport.columns.paxType'),
      "options": _vm.paxTypes,
      "disabled": "",
      "clearable": false
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(_vm.$t('reservation.' + data.label)) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(_vm.$t('reservation.' + data.label)) + " ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.resellTicket.paxType,
      callback: function callback($$v) {
        _vm.$set(_vm.resellTicket, "paxType", $$v);
      },
      expression: "resellTicket.paxType"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('saleReport.columns.paxName')
    }
  }, [_c('b-form-input', {
    staticClass: "bg-white",
    attrs: {
      "placeholder": _vm.$t('saleReport.columns.paxName'),
      "disabled": ""
    },
    on: {
      "input": function input($event) {
        _vm.resellTicket.paxName = _vm.formatterInput($event);
      }
    },
    model: {
      value: _vm.resellTicket.paxName,
      callback: function callback($$v) {
        _vm.$set(_vm.resellTicket, "paxName", $$v);
      },
      expression: "resellTicket.paxName"
    }
  }), _c('small', {
    staticClass: "text-danger"
  }, [_vm._v(_vm._s(_vm.$t('resellTicket.note.paxName')))])], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('saleReport.columns.priceTicket'),
      "rules": "required|min_value:100000"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('saleReport.columns.priceTicket')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("*")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-input', {
          staticClass: "d-none",
          attrs: {
            "value": _vm.resellTicket.priceTicket,
            "state": _vm.getValidationState(validationContext) === false ? false : null,
            "name": _vm.$t('saleReport.columns.priceTicket')
          }
        }), _c('IAmInputMoney', {
          attrs: {
            "value-money": _vm.resellTicket.priceTicket,
            "state": _vm.getValidationState(validationContext) === false ? false : null,
            "on-blur": validationContext.validate
          },
          on: {
            "input": function input(val) {
              return _vm.$emit('update:resell-ticket', Object.assign({}, _vm.resellTicket, {
                priceTicket: val
              }));
            }
          }
        }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('resellTicket.form.price'),
      "rules": "required|only-positive|".concat(_vm.resellTicket.priceTicket - 50000 >= 0 ? "max_value:".concat(_vm.resellTicket.priceTicket - 50000) : '')
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('resellTicket.form.price')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("*")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-input', {
          staticClass: "d-none",
          attrs: {
            "value": _vm.resellTicket.price,
            "state": _vm.getValidationState(validationContext) === false ? false : null,
            "name": _vm.$t('resellTicket.form.price')
          }
        }), _c('IAmInputMoney', {
          attrs: {
            "value-money": _vm.resellTicket.price,
            "state": _vm.getValidationState(validationContext) === false ? false : null,
            "placeholder": _vm.$t('resellTicket.form.ph.price'),
            "on-blur": validationContext.validate
          },
          on: {
            "input": function input(val) {
              return _vm.$emit('update:resell-ticket', Object.assign({}, _vm.resellTicket, {
                price: val
              }));
            }
          }
        }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('resellTicket.form.phone'),
      "vid": "phone",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('resellTicket.form.phone')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("*")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-input', {
          directives: [{
            name: "remove-non-numeric-chars",
            rawName: "v-remove-non-numeric-chars.allNumber",
            modifiers: {
              "allNumber": true
            }
          }],
          attrs: {
            "name": _vm.$t('resellTicket.form.phone'),
            "state": _vm.getValidationState(validationContext) === false ? false : null,
            "placeholder": _vm.$t('resellTicket.form.ph.phone')
          },
          model: {
            value: _vm.resellTicket.phone,
            callback: function callback($$v) {
              _vm.$set(_vm.resellTicket, "phone", $$v);
            },
            expression: "resellTicket.phone"
          }
        }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
      }
    }])
  })], 1), _c('b-col', [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('resellTicket.form.note'),
      "label-for": "note",
      "placeholder": _vm.$t('resellTicket.form.ph.note')
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "note"
    },
    model: {
      value: _vm.resellTicket.note,
      callback: function callback($$v) {
        _vm.$set(_vm.resellTicket, "note", $$v);
      },
      expression: "resellTicket.note"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('validation-provider', {
    attrs: {
      "rules": "required",
      "name": _vm.$t('resellTicket.form.expiredDate'),
      "vid": "resellTicket-expiredDate"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('resellTicket.form.expiredDate')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("*")])];
            },
            proxy: true
          }], null, true)
        }, [_c('flat-pickr', {
          staticClass: "d-none",
          model: {
            value: _vm.resellTicket.expiredDate,
            callback: function callback($$v) {
              _vm.$set(_vm.resellTicket, "expiredDate", $$v);
            },
            expression: "resellTicket.expiredDate"
          }
        }), _c('div', {
          staticClass: "p-0 form-control",
          class: validationContext.errors[0] ? 'is-invalid' : ''
        }, [_c('flat-pickr', {
          attrs: {
            "id": "resellTicket-expiredDate",
            "name": _vm.$t('resellTicket.form.expiredDate'),
            "config": Object.assign({}, _vm.configFlatPickr, {
              altInputClass: 'form-control border-transparent px-50 py-0 height-fit'
            }),
            "placeholder": _vm.$t('topup.placeholderSelectDate')
          },
          model: {
            value: _vm.resellTicket.expiredDate,
            callback: function callback($$v) {
              _vm.$set(_vm.resellTicket, "expiredDate", $$v);
            },
            expression: "resellTicket.expiredDate"
          }
        })], 1), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")]), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v("Anh/Chị kiểm tra ngày hết hạn bảo lưu để chọn ngày hạn đăng bán phù hợp")])], 1)];
      }
    }])
  })], 1)], 1), _c('hr'), _vm.isManager ? _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('saleReport.columns.bookingCode')
    }
  }, [_c('b-form-input', {
    attrs: {
      "disabled": "",
      "formatter": _vm.removeAccentsUpperCaseFormatter
    },
    model: {
      value: _vm.resellTicket.pnr,
      callback: function callback($$v) {
        _vm.$set(_vm.resellTicket, "pnr", $$v);
      },
      expression: "resellTicket.pnr"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "6"
    }
  }), _c('b-col', [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('resellTicket.form.notePrivate'),
      "label-for": "note",
      "placeholder": _vm.$t('resellTicket.form.ph.notePrivate')
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "notePrivate"
    },
    model: {
      value: _vm.resellTicket.notePrivate,
      callback: function callback($$v) {
        _vm.$set(_vm.resellTicket, "notePrivate", $$v);
      },
      expression: "resellTicket.notePrivate"
    }
  })], 1)], 1)], 1) : _vm._e()], 1), _c('div', {
    staticClass: "d-flex-center gap-3"
  }, [_c('b-button', {
    attrs: {
      "variant": "info"
    },
    on: {
      "click": _vm.handleSubmit
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('save')) + " ")]), _c('b-button', {
    attrs: {
      "variant": "secondary"
    },
    on: {
      "click": _vm.handleCancel
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('cancel')) + " ")])], 1)], 1)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }